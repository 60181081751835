// General
import React, { PropsWithChildren } from "react";
import { Provider, useDispatch } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import type { PreloadedState } from "@reduxjs/toolkit";
// Reducers
import usersReducer, { UsersState } from "./users";
import rootReducer, { UserManagerRootState } from "./root";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// Create the root reducer separately, so we can extract the RootState type
const combinedReducer = combineReducers({
  root: rootReducer,
  users: usersReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: combinedReducer,
    preloadedState,
  });
};

const store = setupStore();

export const StoreProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  return <Provider store={store}>{children}</Provider>;
};

// Root Selectors
export const selectUsers = (state: RootState): UsersState => state.users;
export const selectRoot = (state: RootState): UserManagerRootState =>
  state.root;

// Root dispatcher
export const useStoreDispatch = (): StoreDispatch =>
  useDispatch<StoreDispatch>();

export type RootState = ReturnType<typeof combinedReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type StoreDispatch = typeof store.dispatch;

export default store;
