/**
 * ROUTER CONFIGURATION HELPERS
 */
import { isUnity } from "compass-commons";
import { MenuTabRoute } from "../models/appMenu/MenuTabRoute";

export const ROOTPATH = isUnity
  ? `/configure/user-manager`
  : appConfig.COMPASS_USER_MANAGER_PATH;

export default [
  {
    path: MenuTabRoute.Default,
    otherPaths: [],
    name: "navmenu.users",
  },
];
