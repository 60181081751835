import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Models
import { MenuTabRoute } from "../../models/appMenu/MenuTabRoute";

export type UserManagerRootState = {
  selectedTab: MenuTabRoute;
};

const SLICE_NAME = "root";
const initialState: UserManagerRootState = {
  selectedTab: MenuTabRoute.Users,
};

export const rootSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    changeTab: (
      state: UserManagerRootState,
      action: PayloadAction<MenuTabRoute>
    ) => {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const rootActions = rootSlice.actions;

export default rootSlice.reducer;
