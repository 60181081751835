// General
import React from "react";
import { Control } from "react-hook-form";
import { useI18n } from "compass-commons";
import { useSelector } from "react-redux";

// Components
import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import Icon from "@msi/cobalt-react/icon";
import CompassButton from "../../../commons/button";
import UserManagerUserProfile from "../userManagerUserDetails/components/UserManagerUserProfile";
import UserManagerUserGroups from "../userManagerUserDetails/components/UserManagerUserGroups";

// Styles
import "./CreateUserModalDialog.module.css";

// Models
import { UserDTO } from "../../../../models/users/UserDTO";
import { FormErrors } from "../../../../models/utilsValidators/FormErrors";

// Store
import { selectUsers } from "../../../../store";

interface CreateUserModalProps {
  isOpen: boolean;
  control: Control<UserDTO, any>;
  isValid: boolean;
  handleClose: () => void;
  handleSave: () => void;
  errors?: FormErrors<UserDTO>;
}

const CreateUserModal = (props: CreateUserModalProps): JSX.Element => {
  const { t } = useI18n();
  const { loadingUsersCrud } = useSelector(selectUsers);
  const { isOpen, control, isValid, errors, handleClose, handleSave } = props;
  const userId = "new-user-id";

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      open={isOpen}
      data-cr="create-user-plan-modal"
    >
      <div id="create-user-modal">
        <DialogContent className="create-user-modal-dialog">
          <div id="create-user-modal-content-icon">
            <Icon
              id="create-user-modal-icon"
              name="ContactsPeopleAdd"
              size={54}
            />
          </div>
          <div className="create-user-modal-form">
            <div id="create-user-modal-content-header">
              <div id="create-user-title">{t("addNewUserTitle")}</div>
              <div>{t("addNewUserDescription")}</div>
            </div>
            <UserManagerUserProfile
              control={control}
              userId={userId}
              errors={errors}
              inputFullWidth
              inputClassName="create-user-modal-input--round-corners"
            />
            <UserManagerUserGroups userId={userId} control={control} />
            <div className="dialog-actions">
              {(!loadingUsersCrud && (
                <>
                  <CompassButton
                    id="cancel-button"
                    variant="secondary"
                    onClick={handleClose}
                    className="button-width-fit-content"
                  >
                    {t("cancel", { ns: "Shared" })}
                  </CompassButton>
                  <CompassButton
                    id="save-button"
                    data-cr="confirm-integration-save"
                    variant="primary"
                    className="button-width-fit-content"
                    onClick={isValid && handleSave}
                    disabled={loadingUsersCrud || !isValid}
                  >
                    {t("send")}
                  </CompassButton>
                </>
              )) || (
                <div
                  data-cp="player-spinner"
                  className="create-user-modal-spinner-wrapper"
                >
                  <CircularProgress color="inherit" size={20} />
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

CreateUserModal.defaultProps = {
  errors: undefined,
};

export default CreateUserModal;
