import { string } from "zod";

export const RuleStringConstraint = string()
  .min(1, {
    message: "userDetails.propertiesFieldsValidation.required",
  })
  .max(256, {
    message: "userDetails.propertiesFieldsValidation.maxSize",
  });

export const RuleStringOptional = string().optional().nullable();
