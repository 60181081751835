// Generic
import React, { useState, useEffect } from "react";
import { Control } from "react-hook-form";
import { useSelector } from "react-redux";
// Styles
import "./UserManagerUserDetails.module.css";
// Components
import UserManagerUserProfile from "./components/UserManagerUserProfile";
import UserManagerUserDetailsTitle from "./components/UserManagerUserDetailsTitle";
import UserManagerUserGroups from "./components/UserManagerUserGroups";
import DeleteUserModal from "../deleteUserModal/DeleteUserModal";
// Models
import { UserDTO } from "../../../../models/users/UserDTO";
import { FormErrors } from "../../../../models/utilsValidators/FormErrors";
// Store
import { selectUsers } from "../../../../store";

interface UserManagerUserDetailsProps {
  control: Control<UserDTO, any>;
  handleSave: () => void;
  handleDelete: () => void;
  isValid: boolean;
  userId?: string;
  errors?: FormErrors<UserDTO>;
  onClosePanel?: () => void;
}

const UserManagerUserDetails = ({
  control,
  handleSave,
  handleDelete,
  isValid,
  userId,
  errors,
  onClosePanel,
}: UserManagerUserDetailsProps): JSX.Element => {
  const [isReadOnlyMode, setIsReadOnlyMode] = useState<boolean>(true);
  const { loadingUsersCrud } = useSelector(selectUsers);

  const toggleReadOnly = (forceState?: boolean) =>
    setIsReadOnlyMode((prevState: boolean) => forceState ?? !prevState);

  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const showDeleteUserModalFunction = () => {
    setShowDeleteUserModal(true);
  };

  const hideDeleteUserModalFunction = () => {
    setShowDeleteUserModal(false);
  };

  // Everytime a userId change reset readonly state
  useEffect(() => {
    setIsReadOnlyMode(true);
  }, [userId]);

  return (
    (userId && (
      <section
        data-cr="user-manager__details"
        className="user-manager__details-wrapper"
      >
        <UserManagerUserDetailsTitle
          onEdit={() => toggleReadOnly(false)}
          onDelete={showDeleteUserModalFunction}
          onClose={onClosePanel}
          onSave={() => {
            if (isValid) toggleReadOnly(true);
            handleSave();
          }}
          onCancel={() => toggleReadOnly(true)}
          isEditMode={!isReadOnlyMode}
          disableSaveButton={loadingUsersCrud || !isValid}
        />
        <div className="user-manager__details-content">
          <UserManagerUserProfile
            userId={userId}
            readOnlyMode={isReadOnlyMode}
            control={control}
            errors={errors}
            blockEmail
          />
          <UserManagerUserGroups
            userId={userId}
            readOnlyMode={isReadOnlyMode}
            control={control}
          />
          <DeleteUserModal
            isOpen={showDeleteUserModal}
            handleClose={hideDeleteUserModalFunction}
            handleDelete={handleDelete}
          />
        </div>
      </section>
    )) ||
    null
  );
};

UserManagerUserDetails.defaultProps = {
  userId: undefined,
};

export default UserManagerUserDetails;
