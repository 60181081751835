// Generic
import React from "react";
import { useSelector } from "react-redux";
import { Control, Controller } from "react-hook-form";
import { useI18n } from "compass-commons";
// Styles
import {
  CircularProgress,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
// import Input from "@msi/cobalt-react/input";
import "./UserManagerUserGroups.modules.css";
// Models
import { UserDTO } from "../../../../../models/users/UserDTO";
import { UserGroupDTO } from "../../../../../models/users/UserGroupDTO";
// Store
import { selectUsers } from "../../../../../store";

interface UserManagerUserGroupsProps {
  userId: string;
  control: Control<UserDTO, any>;
  readOnlyMode?: boolean;
  dataCr?: string;
}

const UserManagerUserGroups = ({
  userId,
  control,
  readOnlyMode,
  dataCr,
}: UserManagerUserGroupsProps): JSX.Element => {
  const { t } = useI18n();
  const {
    loadingOrgUserGroups,
    orgUserGroupsData: { content: userGroups },
  } = useSelector(selectUsers);

  return (
    <div className="user-manager__user-groups--spacing">
      {(loadingOrgUserGroups && (
        <div
          data-cp="player-spinner"
          className="user-manager-user-groups-loading"
        >
          <CircularProgress color="inherit" />
        </div>
      )) || (
        <FormControl
          id={`user-manager-checkbox-group-${userId}`}
          component="fieldset"
          variant="standard"
          data-cr={dataCr}
          disabled={readOnlyMode}
        >
          <FormLabel
            component="legend"
            className="user-manager__user-groups-label"
          >
            {t("userDetails.propertiesFields.userGroups")}:
          </FormLabel>
          <FormGroup>
            <Controller
              name="userGroups"
              control={control}
              render={({ field }) => (
                <>
                  {userGroups.map((ug: UserGroupDTO) => (
                    <FormControlLabel
                      key={ug.id}
                      label={ug.name}
                      control={
                        <Checkbox
                          className="user-manager__user-groups-checkbox"
                          data-cr={`user-manager-user-groups-checkbox-${ug.id}`}
                          value={JSON.stringify(ug)}
                          // For some reason codesandbox doesn't support `field.value.includes(...)`
                          checked={field.value.some((existingValue) => {
                            return existingValue.name === ug.name;
                          })}
                          onChange={(event, checked) => {
                            if (checked) {
                              field.onChange([
                                ...field.value,
                                JSON.parse(event.target.value),
                              ]);
                            } else {
                              field.onChange(
                                field.value.filter(
                                  (value) =>
                                    value.name !==
                                    JSON.parse(event.target.value).name
                                )
                              );
                            }
                          }}
                        />
                      }
                    />
                  ))}
                </>
              )}
            />
          </FormGroup>
        </FormControl>
      )}
    </div>
  );
};

UserManagerUserGroups.defaultProps = {
  readOnlyMode: false,
  dataCr: undefined,
};

export default UserManagerUserGroups;
