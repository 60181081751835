import React, { PropsWithChildren, useCallback } from "react";
import { Routes, Route } from "react-router-dom";
import { useI18n } from "compass-commons";
// Styles
import "./appLayout.module.css";
// Custom Components
import Navbar from "../commons/navbar/Navbar";
import CustomLink from "../commons/link/CustomLink";
import UserConfigurator from "../userConfigurator/UserConfigurator";
// Router
import RoutesMap from "../../router/Routes";
import RouterGuard from "../../router/RouterGuard";
// Store
import { useStoreDispatch } from "../../store";
import { rootActions } from "../../store/root";
// Models
import { MenuTabRoute } from "../../models/appMenu/MenuTabRoute";

/**
 * Dynamic Router Guard Helper for Config Layout
 * Important to know the tab context
 * Ex. Tab content used to initialize the actions inside breadcrumb content menu
 */
const AppRouterWrapper = ({
  tabName,
  children,
}: PropsWithChildren<{ tabName: MenuTabRoute }>): JSX.Element => {
  const dispatch = useStoreDispatch();

  const onEnterHandler = useCallback(() => {
    dispatch(rootActions.changeTab(tabName));
  }, [tabName]);

  return <RouterGuard onEnter={onEnterHandler}>{children}</RouterGuard>;
};

/**
 * Component responsible for the user configuration page layout
 * @returns JSX.Element
 */
const AppLayout = (): JSX.Element => {
  const { t } = useI18n();

  return (
    <div className="user-manager-layout">
      <section className="user-manager-layout__main">
        <Navbar>
          {RoutesMap.map(({ path, name, otherPaths }) => (
            <CustomLink
              key={`custom-link-${path}`}
              to={`${path}`}
              additionalPaths={otherPaths}
              className="user-manager-subnav__item compass-rounded-corner"
            >
              {t(name)}
            </CustomLink>
          ))}
        </Navbar>
        <div className="user-manager-content">
          <section className="user-manager-content__panel">
            <Routes>
              <Route
                index
                element={
                  <AppRouterWrapper tabName={MenuTabRoute.Users}>
                    <UserConfigurator />
                  </AppRouterWrapper>
                }
              />
            </Routes>
          </section>
        </div>
      </section>
    </div>
  );
};

export default AppLayout;
