import React from "react";
import { useI18n } from "compass-commons";
import Icon from "@msi/cobalt-react/icon";
import CompassButton from "../../../../commons/button";
import "../userManagerHeader.module.css";

interface InviteUserButtonProps {
  inviteUserCallback: () => void;
}

const InviteUserButton = (props: InviteUserButtonProps): JSX.Element => {
  const { t } = useI18n();
  const { inviteUserCallback } = props;

  return (
    <CompassButton id="invite-user-button" onClick={inviteUserCallback}>
      <Icon
        className="user-manager-header-icon"
        name="ic_add"
        title={t("inviteUser")}
        size={20}
      />
    </CompassButton>
  );
};

export default InviteUserButton;
