import React from "react";
import "./userManagerHeader.module.css";
import { useFeatureFlag } from "compass-commons";
import InviteUserButton from "./inviteUserButton/InviteUserButton";
import { INVITE_USERS_FEATURE_FLAG } from "../../../../utils/Constants";

interface UserManagerHeaderProps {
  createUserCallback: () => void;
}

const UserManagerHeader = (props: UserManagerHeaderProps): JSX.Element => {
  const { enabled: inviteUsersFeatureFlagEnabled } = useFeatureFlag(
    appConfig,
    INVITE_USERS_FEATURE_FLAG
  );

  const { createUserCallback } = props;

  return (
    <div className="user-manager-header" data-cr="user-manager-header">
      {(inviteUsersFeatureFlagEnabled && (
        <div className="user-manager-actions-header">
          <InviteUserButton inviteUserCallback={createUserCallback} />
        </div>
      )) ||
        null}
    </div>
  );
};

export default UserManagerHeader;
