/**
 * User Template DTO will be used to represent User object
 */
import * as zod from "zod";
import { UserDTO } from "./UserDTO";

export const InviteUserDTO = UserDTO.omit({
  userId: true,
}).required();

export type InviteUserDTO = zod.infer<typeof InviteUserDTO>;
