import { BehaviorSubject } from "rxjs";
import { IAlertNotification } from "dms-lib";

class StateService {
  public alertSubject: BehaviorSubject<IAlertNotification>;

  constructor() {
    this.alertSubject = new BehaviorSubject<IAlertNotification>(null);
  }
}

export default StateService;
