// Generic
import {
  HttpClient,
  AllowedModules,
  CompassAuth,
} from "compass-shared-services";
import { getUrl } from "compass-commons";
// Models
import { UserResponseDTO } from "../models/users/UserResponseDTO";
import { UserDTO } from "../models/users/UserDTO";
import { InviteUserDTO } from "../models/users/InviteUserDTO";
import { UpdateUserDTO } from "../models/users/UpdateUserDTO";
import { UserGroupsFilter } from "../models/users/UserGroupDTO";
import PageUserGroup from "../models/users/PageUserGroup";
import { buildErrorMessage } from "../utils/Util";

const USER_MANAGER_URL = appConfig.USER_MANAGER_SERVICE_PATH;
const httpClient = new HttpClient(appConfig);
const compassAuth = CompassAuth(appConfig.SERVER_URL, appConfig.NAMESPACE);

const URL_PATHS = {
  GET_ALL_USERS: `${USER_MANAGER_URL}/manager/users?page=(page)`,
  GET_ALL_USER_GROUPS: `${USER_MANAGER_URL}/manager/user-groups`,
  GET_UPDATE_USER: `${USER_MANAGER_URL}/manager/users/(userId)`,
  CREATE_USER: `${USER_MANAGER_URL}/manager/users/invite-to-customer`,
  GET_USER_USERGROUPS: `${USER_MANAGER_URL}/manager/user-groups/logged-user`,
  DELETE_USER: `${USER_MANAGER_URL}/manager/users/(userId)`,
};

class UserManagerService {
  static async getAllUsers(page: number): Promise<UserResponseDTO> {
    const uri = {
      page,
    };
    const urlPath = getUrl(URL_PATHS.GET_ALL_USERS, uri);
    return httpClient
      .get<UserResponseDTO>({
        url: urlPath,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(buildErrorMessage(error));
      });
  }

  static async createUser(user: InviteUserDTO): Promise<UserDTO> {
    const urlPath = URL_PATHS.CREATE_USER;
    return httpClient
      .post<UserDTO>({ url: urlPath, payload: user })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(buildErrorMessage(error));
      });
  }

  static async updateUser(
    userId: string,
    user: UpdateUserDTO
  ): Promise<string> {
    const urlPath = getUrl(URL_PATHS.GET_UPDATE_USER, {
      userId,
    });
    return httpClient
      .put<string>({ url: urlPath, payload: user })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(buildErrorMessage(error));
      });
  }

  static async getUserGroups(
    userGroupsParams: UserGroupsFilter
  ): Promise<PageUserGroup> {
    return httpClient
      .get<PageUserGroup>({
        url: URL_PATHS.GET_ALL_USER_GROUPS,
        config: {
          params: {
            ...userGroupsParams,
          },
        },
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(buildErrorMessage(error));
      });
  }

  static async isUserAllowed(): Promise<boolean> {
    try {
      return await compassAuth.isUserAllowed(
        URL_PATHS.GET_USER_USERGROUPS,
        AllowedModules.USERMANAGER
      );
    } catch (error) {
      return false;
    }
  }

  static async deleteUser(userId: string): Promise<string> {
    const urlPath = getUrl(URL_PATHS.DELETE_USER, {
      userId,
    });
    return httpClient
      .delete<string>({ url: urlPath })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(buildErrorMessage(error));
      });
  }
}

export default UserManagerService;
