/**
 * User DTO will be used to represent User object
 */
import { z } from "zod";
import { UserGroupDTO } from "./UserGroupDTO";
import { RuleStringConstraint } from "../utilsValidators";

export const UserDTO = z.object({
  userId: RuleStringConstraint,
  firstName: RuleStringConstraint,
  lastName: RuleStringConstraint,
  email: z
    .string()
    .email({ message: "userDetails.propertiesFieldsValidation.email" }),
  userGroups: UserGroupDTO.array(),
});

export type UserDTO = z.infer<typeof UserDTO>;
