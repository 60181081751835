// Generic
import React, { useEffect } from "react";
import { useI18n } from "compass-commons";
// Styles
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import "./UserManagerListPanel.module.css";
// Components
// Models
import { UserDTO } from "../../../../models/users/UserDTO";
// Store
import { useStoreDispatch } from "../../../../store";
import { usersActions } from "../../../../store/users";

interface UserManagerListPanelProps {
  usersList: UserDTO[];
  selectedUserId: string;
  onUserClick: (user: UserDTO) => void;
}

const UserManagerListPanel = (
  props: UserManagerListPanelProps
): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useStoreDispatch();
  const { usersList, selectedUserId, onUserClick } = props;

  const getGroupsInformation = (user: UserDTO) => {
    const sortedUserGroups = user?.userGroups?.slice();
    sortedUserGroups.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    if (sortedUserGroups?.length > 0) {
      return (
        <div className="col user-manager-list-el-col">
          {sortedUserGroups?.[0]?.name}
          {sortedUserGroups?.length > 1 && <MoreHorizIcon />}
        </div>
      );
    }
    return <div className="col user-manager-list-el-col" />;
  };

  useEffect(() => {
    if (selectedUserId)
      dispatch(
        usersActions.selectUser(
          usersList.find((user) => user.userId === selectedUserId)
        )
      );
  }, []);

  return (
    usersList?.length > 0 && (
      <>
        <div className="row user-manager-table-header user-manager-list-header">
          <div className="col header-col">{t("userName")}</div>
          <div className="col header-col">{t("userEmail")}</div>
          <div className="col header-col">{t("userGroup")}</div>
          <div className="header-col-button" />
        </div>
        <div
          className="user-manager-list user-manager-table-body"
          data-cr="user-manager-list"
        >
          {usersList.map((user) => (
            <button
              type="button"
              key={user.userId}
              className={`row user-manager-list-el${
                (selectedUserId === user.userId &&
                  " user-manager-list--active") ||
                ""
              }`}
              onClick={() => onUserClick(user)}
            >
              <div className="col user-manager-list-el-col">
                {user.firstName} {user.lastName}
              </div>
              <div className="col user-manager-list-el-col">{user.email}</div>
              {getGroupsInformation(user)}
            </button>
          ))}
        </div>
      </>
    )
  );
};

export default UserManagerListPanel;
