/**
 * User Template DTO will be used to represent User object
 */
import * as zod from "zod";
import { UserDTO } from "./UserDTO";

export const UpdateUserDTO = UserDTO.omit({
  userId: true,
  email: true,
}).required();

export type UpdateUserDTO = zod.infer<typeof UpdateUserDTO>;
