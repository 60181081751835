// Generic
import React from "react";
import { useI18n } from "compass-commons";
// Styles
import Icon from "@msi/cobalt-react/icon";
import "./UserManagerUserDetailsTitle.modules.css";
// Components
import CompassButton from "../../../../commons/button";

interface UserManagerUserDetailsTitleProps {
  onEdit: () => void;
  onDelete: () => void;
  onClose: () => void;
  onSave: () => void;
  onCancel: () => void;
  isEditMode: boolean;
  disableSaveButton: boolean;
}

const UserManagerUserDetailsTitle = ({
  onEdit,
  onDelete,
  onClose,
  onSave,
  onCancel,
  isEditMode,
  disableSaveButton,
}: UserManagerUserDetailsTitleProps): JSX.Element => {
  const { t } = useI18n();

  return (
    <header className="user-managers__details-header">
      <h4 className="user-managers__details-title">{t("userDetails.title")}</h4>
      <div className="user-managers__details-actions">
        {(!isEditMode && (
          <>
            <CompassButton onClick={onDelete}>
              <Icon
                id="delete-user-icon"
                name="trash"
                title={t("deleteUser")}
                size={20}
              />
            </CompassButton>
            <CompassButton onClick={onEdit}>
              <Icon
                className="user-manager-header-icon"
                id="edit"
                name="ic_edit"
                title={t("edit", { ns: "Shared" })}
                size={20}
              />
            </CompassButton>
            <CompassButton
              id="user-managers-details-title-close-button"
              onClick={onClose}
            >
              <Icon
                className="user-manager-header-icon"
                name="ic_remove"
                title={t("close", { ns: "Shared" })}
                size={20}
              />
            </CompassButton>
          </>
        )) || (
          <>
            <CompassButton
              dataCr="user-manager-save-button"
              onClick={onSave}
              variant="primary"
              disabled={disableSaveButton}
            >
              <span>{t("save", { ns: "Shared" })}</span>
            </CompassButton>
            <CompassButton
              dataCr="cancel-button"
              onClick={onCancel}
              variant="secondary"
            >
              <span>{t("cancel", { ns: "Shared" })}</span>
            </CompassButton>
          </>
        )}
      </div>
    </header>
  );
};

export default UserManagerUserDetailsTitle;
