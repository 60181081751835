import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import { useI18n } from "compass-commons";
import Icon from "@msi/cobalt-react/icon";
import "../createUserModal/CreateUserModalDialog.module.css";
import "./UserCreatedSuccessfullyModal.module.css";
import { UserDTO } from "../../../../models/users/UserDTO";
import CompassButton from "../../../commons/button";

interface UserCreatedSuccessfullyModalProps {
  isOpen: boolean;
  user: UserDTO;
  handleClose: () => void;
}

const UserCreatedSuccessfullyModal = (
  props: UserCreatedSuccessfullyModalProps
): JSX.Element => {
  const { t } = useI18n();
  const { isOpen, user, handleClose } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      open={isOpen}
      data-cr="user-created-successfully-plan-modal"
    >
      <div className="close-modal">
        <CompassButton
          id="close-user-created-successfully"
          className="close-user-created-successfully-button"
          onClick={handleClose}
        >
          <Icon id="close-user-created-successfully" name="remove" size={36} />
        </CompassButton>
      </div>
      <div id="user-created-successfully">
        <DialogContent className="user-created-successfully-modal">
          <div id="user-created-successfully-content-icon">
            <Icon
              id="user-created-successfully-icon"
              name="ContactsPeopleAdd"
              size={24}
            />
          </div>
          <div className="user-created-successfully-details">
            <div
              id="user-created-successfully-details-header"
              className="invitation-sent-title"
            >
              {t("invitationSent")}
            </div>
            <div className="user-info" data-cr="user-created-successfully-name">
              {`${user?.firstName} ${user?.lastName}`}
            </div>
            <div
              className="user-info"
              data-cr="user-created-successfully-email"
            >
              {user?.email}
            </div>
            {user?.userGroups.map((ug) => (
              <div
                data-cr={`user-created-successfully-user-group-${ug.id}`}
                className="user-info"
                key={`created-user-ug-${ug.id}`}
              >
                {ug.name}
              </div>
            ))}
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default UserCreatedSuccessfullyModal;
