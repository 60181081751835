// General
import { createAsyncThunk } from "@reduxjs/toolkit";
// Services
import UserManagerService from "../../../services/UserManagerService";
// Models
import { UserGroupsFilter } from "../../../models/users/UserGroupDTO";
import { InviteUserDTO } from "../../../models/users/InviteUserDTO";
import { UpdateUserDTO } from "../../../models/users/UpdateUserDTO";
// Store
import { usersActions } from "..";

/* eslint-disable no-return-await */
function createExtraActions() {
  const getAllUsers = () => {
    return createAsyncThunk(
      `users/getAllUsers`,
      // eslint-disable-next-line
      async (page: number) => await UserManagerService.getAllUsers(page)
    );
  };

  const createUser = () => {
    return createAsyncThunk(
      `users/createUser`,
      // eslint-disable-next-line
      async (userToAdd: InviteUserDTO, { dispatch }) => {
        const result = await UserManagerService.createUser(userToAdd);
        // Fetch first page after creating user and go to that same page
        await dispatch(usersActions.getAllUsers(1));
        return result;
      }
    );
  };

  const updateUser = () => {
    return createAsyncThunk(
      `users/updateUser`,
      // eslint-disable-next-line
      async (
        {
          userId,
          userToUpdate,
          currentPage,
        }: { userId: string; userToUpdate: UpdateUserDTO; currentPage: number },
        { dispatch }
      ) => {
        const result = await UserManagerService.updateUser(
          userId,
          userToUpdate
        );
        // Fetch first page after creating user and go to that same page
        await dispatch(usersActions.getAllUsers(currentPage));
        return result;
      }
    );
  };

  const getAllOrgUserGroups = () => {
    return createAsyncThunk(
      `users/getAllOrgUserGroups`,
      // eslint-disable-next-line
      async (userGroupsParams: UserGroupsFilter) =>
        await UserManagerService.getUserGroups(userGroupsParams)
    );
  };

  const deleteUser = () => {
    return createAsyncThunk(
      `users/deleteUser`,
      // eslint-disable-next-line
      async (
        { userId, currentPage }: { userId: string; currentPage: number },
        { dispatch }
      ) => {
        const result = await UserManagerService.deleteUser(userId);
        // Fetch current page after deleting user and go to that same page.
        await dispatch(usersActions.getAllUsers(currentPage));
        return result;
      }
    );
  };

  return {
    getAllUsers: getAllUsers(),
    createUser: createUser(),
    updateUser: updateUser(),
    getAllOrgUserGroups: getAllOrgUserGroups(),
    deleteUser: deleteUser(),
  };
}

const usersExtraActions = createExtraActions();

export default usersExtraActions;
