import React, { PropsWithChildren } from "react";
// Styles
import "./compassButton.module.css";

interface CompassButtonProps {
  id?: string;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  dataCr?: string;
  variant?: "primary" | "secondary";
}
/**
 * Component that represents a compass icon button
 * @returns JSX.Element
 */
const CompassButton = ({
  id,
  disabled,
  className,
  variant,
  onClick,
  dataCr,
  children,
}: PropsWithChildren<CompassButtonProps>): JSX.Element => {
  return (
    <button
      id={id}
      data-cr={dataCr}
      type="button"
      onClick={() => {
        // eslint-disable-next-line
        !disabled && onClick();
      }}
      disabled={disabled}
      className={`config-button config-no-button-style${
        className ? ` ${className}` : ""
      }${`${variant ? ` config-button--${variant}` : ""}`}`}
    >
      {children}
    </button>
  );
};

CompassButton.defaultProps = {
  id: undefined,
  disabled: false,
  onClick: undefined,
  className: undefined,
};

export default CompassButton;
