// General
import React, { PropsWithChildren } from "react";
// Styles
import "./Navbar.module.css";

/**
 * Wrapper for the navbar menu
 * @returns JSX.Element
 */
const Navbar = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  return (
    <nav className="user-manager-layout__subnav" data-cr="user-manager-navbar">
      <ul className="user-manager-subnav__menu">{children}</ul>
    </nav>
  );
};

export default Navbar;
