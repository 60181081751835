/**
 * User Group DTO will be used to represent a group object
 */
import { z, object } from "zod";
import { RuleStringConstraint } from "../utilsValidators";

export const UserGroupDTO = object({
  id: RuleStringConstraint,
  name: RuleStringConstraint,
});

export interface UserGroupsFilter {
  pageIndex?: number;
  pageSize?: number;
}

export type UserGroupDTO = z.infer<typeof UserGroupDTO>;
