import React, { PropsWithChildren, useEffect } from "react";

interface RouterGuardProp {
  onEnter: () => void;
  onLeave?: () => void;
}

const RouterGuard = ({
  onEnter,
  children,
}: PropsWithChildren<RouterGuardProp>): JSX.Element => {
  useEffect(() => {
    onEnter();
  }, [onEnter]);

  return <>{children}</>;
};

export default RouterGuard;
