// Generic
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useI18n } from "compass-commons";
// Styles
import "./UserManagerUserProfile.modules.css";
import Input from "@msi/cobalt-react/input";
// Models
import { UserDTO } from "../../../../../models/users/UserDTO";
import { FormErrors } from "../../../../../models/utilsValidators/FormErrors";

interface UserManagerUserProfileProps {
  userId: string;
  control: Control<UserDTO, any>;
  readOnlyMode?: boolean;
  dataCr?: string;
  errors?: FormErrors<UserDTO>;
  inputFullWidth?: boolean;
  inputClassName?: string;
  blockEmail?: boolean;
}

const UserManagerUserProfile = ({
  userId,
  readOnlyMode,
  control,
  dataCr,
  errors,
  inputFullWidth,
  inputClassName,
  blockEmail,
}: UserManagerUserProfileProps): JSX.Element => {
  const { t } = useI18n();

  return (
    <>
      <div className="user-manager__perfil--spacing">
        <label
          htmlFor={`user-manager-firstname-${userId}`}
          className="user-manager__input-label"
        >
          {t("userDetails.propertiesFields.firstName")}:
        </label>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => {
            return (
              <Input
                {...field}
                data-cr={dataCr}
                id={`user-manager-firstname-${userId}`}
                className={`user-manager__input-border ${
                  (inputFullWidth && "user-manager__input--max-width") ||
                  "user-manager__input--half-width"
                }${(readOnlyMode && " user-manager__input-readonly") || ""}${
                  (inputClassName && ` ${inputClassName}`) || ""
                }`}
                readOnly={readOnlyMode}
                aria-label="users-property-firstName"
                placeholder={t("userDetails.placeholders.firstName")}
                invalid={!!errors?.firstName}
                errorMessage={t(errors?.firstName?.message, {
                  label: t("userDetails.fields.firstName"),
                })}
              />
            );
          }}
        />
      </div>
      <div className="user-manager__perfil--spacing">
        <label
          htmlFor={`user-manager-lastname-${userId}`}
          className="user-manager__input-label"
        >
          {t("userDetails.propertiesFields.lastName")}:
        </label>
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => {
            return (
              <Input
                {...field}
                data-cr={dataCr}
                id={`user-manager-lastname-${userId}`}
                className={`user-manager__input-border ${
                  (inputFullWidth && "user-manager__input--max-width") ||
                  "user-manager__input--half-width"
                }${(readOnlyMode && " user-manager__input-readonly") || ""}${
                  (inputClassName && ` ${inputClassName}`) || ""
                }`}
                readOnly={readOnlyMode}
                aria-label="users-property-lastName"
                placeholder={t("userDetails.placeholders.lastName")}
                invalid={!!errors?.lastName}
                errorMessage={t(errors?.lastName?.message, {
                  label: t("userDetails.fields.lastName"),
                })}
              />
            );
          }}
        />
      </div>
      <div className="user-manager__perfil--spacing">
        <label
          htmlFor={`user-manager-email-${userId}`}
          className="user-manager__input-label"
        >
          {t("userDetails.propertiesFields.email")}:
        </label>
        <Controller
          name="email"
          control={control}
          render={({ field }) => {
            return (
              <Input
                {...field}
                data-cr={dataCr}
                id={`user-manager-email-${userId}`}
                className={`user-manager__input-border ${
                  (inputFullWidth && "user-manager__input--max-width") ||
                  "user-manager__input--half-width"
                }${
                  ((blockEmail || readOnlyMode) &&
                    " user-manager__input-readonly") ||
                  ""
                }${(inputClassName && ` ${inputClassName}`) || ""}`}
                readOnly={blockEmail || readOnlyMode}
                aria-label="users-property-email"
                placeholder={t("userDetails.placeholders.email")}
                invalid={!!errors?.email}
                errorMessage={t(errors?.email?.message, {
                  label: t("userDetails.fields.email"),
                })}
              />
            );
          }}
        />
      </div>
    </>
  );
};

UserManagerUserProfile.defaultProps = {
  readOnlyMode: false,
  dataCr: undefined,
  errors: undefined,
  inputClassName: undefined,
  inputFullWidth: false,
  blockEmail: false,
};

export default UserManagerUserProfile;
