// Generic
import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";
// Store
import { UsersState } from "..";
// Actions
import usersExtraActions from "../extraActions";
// Models
import { UserResponseDTO } from "../../../models/users/UserResponseDTO";
import PageUserGroup from "../../../models/users/PageUserGroup";
// Utils

/* eslint-disable no-param-reassign, @typescript-eslint/no-explicit-any */

const usersGetAllReducer = (builder: ActionReducerMapBuilder<UsersState>) => {
  builder
    .addCase(usersExtraActions.getAllUsers.pending, (state: UsersState) => {
      state.loadingUsersList = true;
      state.usersError = null;
    })
    .addCase(
      usersExtraActions.getAllUsers.fulfilled,
      (state: UsersState, action: PayloadAction<UserResponseDTO>) => {
        state.loadingUsersList = false;
        state.usersList = action.payload.users;
        state.totalPages = action.payload.totalPages;
      }
    )
    .addCase(
      usersExtraActions.getAllUsers.rejected,
      (state: UsersState, action) => {
        state.loadingUsersList = false;
        state.usersError = {
          label: "usersNotFound",
          message: action.error.message,
        };
      }
    );
};

const usersUpdateUserReducer = (
  builder: ActionReducerMapBuilder<UsersState>
) => {
  builder
    .addCase(usersExtraActions.updateUser.pending, (state: UsersState) => {
      state.loadingUsersCrud = true;
      state.usersError = null;
    })
    .addCase(usersExtraActions.updateUser.fulfilled, (state: UsersState) => {
      state.loadingUsersCrud = false;
    })
    .addCase(
      usersExtraActions.updateUser.rejected,
      (state: UsersState, action) => {
        state.loadingUsersCrud = false;
        state.usersError = {
          label: "userNotUpdated",
          message: action.error.message,
        };
      }
    );
};

const usersCreateUserReducer = (
  builder: ActionReducerMapBuilder<UsersState>
) => {
  builder
    .addCase(usersExtraActions.createUser.pending, (state: UsersState) => {
      state.loadingUsersCrud = true;
      state.usersError = null;
    })
    .addCase(usersExtraActions.createUser.fulfilled, (state: UsersState) => {
      state.loadingUsersCrud = false;
    })
    .addCase(
      usersExtraActions.createUser.rejected,
      (state: UsersState, action) => {
        state.loadingUsersCrud = false;
        state.usersError = {
          label: "userNotCreated",
          message: action.error.message,
        };
      }
    );
};

const usersGetUserGroupsReducer = (
  builder: ActionReducerMapBuilder<UsersState>
) => {
  builder
    .addCase(
      usersExtraActions.getAllOrgUserGroups.pending,
      (state: UsersState) => {
        state.loadingOrgUserGroups = true;
        state.usersError = null;
      }
    )
    .addCase(
      usersExtraActions.getAllOrgUserGroups.fulfilled,
      (state: UsersState, action: PayloadAction<PageUserGroup>) => {
        state.loadingOrgUserGroups = false;
        state.orgUserGroupsData = action.payload;
      }
    )
    .addCase(
      usersExtraActions.getAllOrgUserGroups.rejected,
      (state: UsersState, action) => {
        state.loadingOrgUserGroups = false;
        state.usersError = {
          label: "usersGroupsNotFound",
          message: action.error.message,
        };
      }
    );
};

const usersDeleteUserReducer = (
  builder: ActionReducerMapBuilder<UsersState>
) => {
  builder
    .addCase(usersExtraActions.deleteUser.pending, (state: UsersState) => {
      state.loadingUsersCrud = true;
      state.usersError = null;
    })
    .addCase(usersExtraActions.deleteUser.fulfilled, (state: UsersState) => {
      state.loadingUsersCrud = false;
    })
    .addCase(
      usersExtraActions.deleteUser.rejected,
      (state: UsersState, action) => {
        state.loadingUsersCrud = false;
        state.usersError = {
          label: "userNotDeleted",
          message: action.error.message,
        };
      }
    );
};

const usersExtraReducers = (
  builder: ActionReducerMapBuilder<UsersState>
): any => {
  usersGetAllReducer(builder);
  usersCreateUserReducer(builder);
  usersUpdateUserReducer(builder);
  usersGetUserGroupsReducer(builder);
  usersDeleteUserReducer(builder);
};

export default usersExtraReducers;
