// Generic
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useI18n } from "compass-commons";
// Styles
import { CircularProgress, Pagination } from "@mui/material";
import "./userConfigurator.module.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
// Services
import StateService from "../../services/StateService";
// Components
import UserManagerListPanel from "./components/userManagerListPanel/UserManagerListPanel";
import CreateUserModal from "./components/createUserModal/CreateUserModal";
import UserCreatedSuccessfullyModal from "./components/userCreatedSuccessfullyModal/UserCreatedSuccessfullyModal";
import UserManagerHeader from "./components/userManagerHeader/UserManagerHeader";
import UserManagerUserDetails from "./components/userManagerUserDetails/UserManagerUserDetails";
// Models
import { useStateContext } from "../../contexts/StateContext";
import { InviteUserDTO } from "../../models/users/InviteUserDTO";
import { UpdateUserDTO } from "../../models/users/UpdateUserDTO";
import { UserDTO } from "../../models/users/UserDTO";
// Store
import { selectUsers, useStoreDispatch } from "../../store";
import { usersActions, resetUserToDefaults } from "../../store/users";
// Utils
import { RouteSearchParams } from "../../router/helpers/Utils";

const IntegrationManagerConfigurator = (): JSX.Element => {
  const { t } = useI18n();
  const [searchParams, setSearchParams] = useSearchParams();

  const stateService: StateService = useStateContext();
  const { alertSubject } = stateService;

  const dispatch = useStoreDispatch();
  const { usersList, totalPages, loadingUsersList, usersError, selectUser } =
    useSelector(selectUsers);

  const pageParam = searchParams.get(RouteSearchParams.PAGE);
  const userIdParam = searchParams.get(RouteSearchParams.USER_ID);
  const [page, setPage] = useState(parseInt(pageParam, 10) || 1);

  // Modals
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [
    showUserCreatedSuccessfullyModal,
    setShowUserCreatedSuccessfullyModal,
  ] = useState(false);

  const {
    reset: resetAll,
    control: controlUser,
    formState: { errors: errorsData, isValid },
    clearErrors,
    getValues,
    handleSubmit,
  } = useForm<UserDTO>({
    mode: "all",
    resolver: zodResolver(UserDTO), // We can use the dto itself to perform validation
    defaultValues: resetUserToDefaults,
  });

  // Manipulate URL Search Params
  const updateUrlSearchParams = (
    key: string,
    value: string,
    forceFullUpdate = false
  ) => {
    searchParams.set(key, value);
    const valueToSet = (forceFullUpdate && { [key]: value }) || searchParams;
    setSearchParams(valueToSet);
  };

  const removeUrlSearchParams = (key: string) => {
    if (searchParams.has(key)) {
      searchParams.delete(key);
      setSearchParams(searchParams);
    }
  };

  const alertMessageCallback = useCallback((error: boolean, msg: string) => {
    alertSubject.next({ title: msg, severity: error ? "error" : "success" });
  }, []);

  const loadUsers = () => {
    dispatch(usersActions.getAllUsers(page));
  };

  const changeSelectUser = (selectedUser: UserDTO) => {
    dispatch(usersActions.selectUser(selectedUser));
    updateUrlSearchParams(RouteSearchParams.USER_ID, selectedUser.userId);
  };

  const deselectUser = () => {
    dispatch(usersActions.selectUser(undefined));
    removeUrlSearchParams(RouteSearchParams.USER_ID);
  };

  const onPageChanged = (selectedPage) => {
    if (page !== selectedPage) {
      deselectUser();
      setPage(selectedPage);
    }
  };

  const showCreateUserModalFunction = () => {
    deselectUser();
    setShowCreateUserModal(true);
  };

  const hideCreateUserModal = () => {
    setShowCreateUserModal(false);
    clearErrors();
  };

  const hideUserCreatedSuccessfullyModal = () => {
    setShowUserCreatedSuccessfullyModal(false);
    resetAll(resetUserToDefaults);
  };

  useEffect(() => {
    loadUsers();
    updateUrlSearchParams(RouteSearchParams.PAGE, String(page), true);
  }, [page]);

  useEffect(() => {
    if (
      totalPages > 0 &&
      totalPages < page &&
      searchParams.has(RouteSearchParams.PAGE)
    ) {
      setPage(totalPages);
    }
  }, [totalPages]);

  useEffect(() => {
    if (!usersError) return;
    alertMessageCallback(true, `${t(usersError.label)}`);
  }, [usersError]);

  useEffect(() => {
    resetAll(selectUser ?? resetUserToDefaults);
  }, [selectUser]);

  useEffect(() => {
    dispatch(usersActions.getAllOrgUserGroups({ pageIndex: 0 }));
  }, []);

  const createUser = async (newUser: UserDTO) => {
    try {
      await dispatch(
        usersActions.createUser(InviteUserDTO.parse(newUser))
      ).unwrap();
      setShowUserCreatedSuccessfullyModal(true);
      setPage(1);
      alertMessageCallback(false, t("createOk", { ns: "Shared" }));
    } finally {
      hideCreateUserModal();
    }
  };

  const updateUser = async (newUser: UserDTO) => {
    dispatch(
      usersActions.updateUser({
        userId: newUser.userId,
        userToUpdate: UpdateUserDTO.parse(newUser),
        currentPage: page,
      })
    );
  };

  const handleDeleteUser = async () => {
    await dispatch(
      usersActions.deleteUser({
        userId: selectUser?.userId || userIdParam,
        currentPage: page,
      })
    ).unwrap();
    deselectUser();
    alertMessageCallback(false, t("deleteOk", { ns: "Shared" }));
  };

  return (
    <>
      <div
        data-cr="user-manager-configurator"
        className="user-manager-panel compass-rounded-corner"
      >
        {(loadingUsersList && (
          <div data-cp="player-spinner" className="user-manager-loading">
            <CircularProgress color="inherit" />
          </div>
        )) || (
          <>
            <UserManagerHeader
              createUserCallback={showCreateUserModalFunction}
            />
            {usersList?.length > 0 ? (
              <section
                data-cr="user-manager-main"
                className={`user-manager-main ${
                  (!selectUser && " grid-collapse") || ""
                }`}
              >
                <UserManagerListPanel
                  usersList={usersList}
                  selectedUserId={selectUser?.userId || userIdParam}
                  onUserClick={changeSelectUser}
                />
                <div className="user-manager-pagination">
                  <Pagination
                    data-cr="user-pagination"
                    className="user-pagination"
                    onChange={(event, newPage) => onPageChanged(newPage)}
                    count={totalPages}
                    page={page}
                    showFirstButton
                    showLastButton
                  />
                </div>
                <div className="user-manager-details">
                  <UserManagerUserDetails
                    control={controlUser}
                    isValid={isValid}
                    handleSave={handleSubmit(updateUser)}
                    handleDelete={handleDeleteUser}
                    userId={selectUser?.userId}
                    onClosePanel={deselectUser}
                    errors={errorsData}
                  />
                </div>
              </section>
            ) : (
              <div data-cr="user-list-empty" className="user-list-empty">
                {t("emptyUserList")}
              </div>
            )}
          </>
        )}
      </div>
      <CreateUserModal
        isOpen={showCreateUserModal}
        control={controlUser}
        isValid={isValid}
        errors={errorsData}
        handleClose={hideCreateUserModal}
        handleSave={handleSubmit(createUser)}
      />
      <UserCreatedSuccessfullyModal
        isOpen={showUserCreatedSuccessfullyModal}
        user={getValues()}
        handleClose={hideUserCreatedSuccessfullyModal}
      />
    </>
  );
};

export default IntegrationManagerConfigurator;
