import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Reducers
import usersExtraReducers from "./extraReducers";
// Actions
import usersExtraActions from "./extraActions";
// Models
import { UserDTO } from "../../models/users/UserDTO";
import PageUserGroup from "../../models/users/PageUserGroup";

/* eslint-disable no-param-reassign */

type UsersErrorType = { label: string; message: string };

export type UsersState = {
  usersList: UserDTO[];
  loadingUsersList: boolean;
  selectUser?: UserDTO;
  totalPages: number;
  orgUserGroupsData: PageUserGroup;
  loadingOrgUserGroups: boolean;
  loadingUsersCrud: boolean;
  usersError?: UsersErrorType;
};

export const resetUserToDefaults = {
  userId: "new-user-id",
  firstName: "",
  lastName: "",
  email: "",
  userGroups: [],
};

const SLICE_NAME = "users";

export const initialState: UsersState = {
  usersList: [],
  loadingUsersList: false,
  selectUser: undefined,
  totalPages: 0,
  orgUserGroupsData: {
    content: [],
  },
  loadingOrgUserGroups: false,
  loadingUsersCrud: false,
  usersError: undefined,
};

/**
 * Slice to manipulate all operations related to subsystems
 */
export const usersSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    selectUser: (state: UsersState, action: PayloadAction<UserDTO>) => {
      state.selectUser = action.payload;
    },
  },
  extraReducers: usersExtraReducers,
});

// Action creators are generated for each case reducer function
export const usersActions = {
  ...usersSlice.actions,
  ...usersExtraActions,
};

export default usersSlice.reducer;
